h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

p {
  margin: 0;
  padding: 0;
}

.text-size {
  &-sm {
    font-size: 0.875rem;
    font-weight: 400;
  }

  &-lg {
    font-size: 1.25rem;
  }
}

.text-align {
  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.text-color {
  &-primary {
    color: var(--color-primary) !important;
  }

  &-secondary {
    color: var(--color-secondary) !important;
  }

  &-muted {
    color: var(--color-typography-muted-30) !important;
  }

  &-dark {
    color: var(--color-typography-dark) !important;
  }

  &-light {
    color: var(--color-typography-light) !important;
  }

  &-success {
    color: var(--color-attention-success) !important;
  }

  &-alert {
    color: var(--color-attention-error) !important;
  }

  &-warning {
    color: var(--color-attention-warning) !important;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
