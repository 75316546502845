csgofast {
  &-provably-fair {
    .mat-mdc-tab-group {
      gap: 2rem;
      height: 100%;
      .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
          .mat-mdc-tab-list {
            .mat-mdc-tab-labels {
              .mdc-tab {
                color: var(--color-typography-muted-50);
                &--active {
                  color: var(--color-typography-light);
                  .mdc-tab-indicator {
                    .mdc-tab-indicator__content {
                      color: var(--color-primary);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .mat-mdc-tab-body-wrapper {
        height: 100%;
        border-radius: 0.5rem;
        overflow: hidden;
        background: linear-gradient(180deg, var(--color-theme-400) -100%, var(--color-theme-500) 100%);
      }
    }
  }
}

