.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1 * $gutter;
    margin-left: -1 * $gutter;
    margin-top: -1 * $gutter;
}

.grid > .col,
.grid > [class*="col"] {
    box-sizing: border-box;
}

.grid-nogutter {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*="col-"] {
    padding: 0;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    padding: $gutter;
}

.col-fixed {
    flex: 0 0 auto;
    padding: $gutter;
}

$grid-columns: (
    "col-1": 5%,
    "col-2": 10%,
    "col-3": 15%,
    "col-4": 20%,
    "col-5": 25%,
    "col-6": 30%,
    "col-7": 35%,
    "col-8": 40%,
    "col-9": 45%,
    "col-10": 50%,
    "col-11": 55%,
    "col-12": 60%,
    "col-13": 65%,
    "col-14": 70%,
    "col-15": 75%,
    "col-16": 80%,
    "col-17": 85%,
    "col-18": 90%,
    "col-19": 95%,
    "col-20": 100%
);
// $grid-columns: (
//     "col-1": 8.3333%,
//     "col-2": 16.6667%,
//     "col-3": 25%,
//     "col-4": 33.3333%,
//     "col-5": 41.6667%,
//     "col-6": 50%,
//     "col-7": 58.3333%,
//     "col-8": 66.6667%,
//     "col-9": 75%,
//     "col-10": 83.3333%,
//     "col-11": 91.6667%,
//     "col-12": 100%
// );

$grid-column-offsets: (
    "col-offset-0": 0,
    "col-offset-1": 5%,
    "col-offset-2": 10%,
    "col-offset-3": 15%,
    "col-offset-4": 20%,
    "col-offset-5": 25%,
    "col-offset-6": 30%,
    "col-offset-7": 35%,
    "col-offset-8": 40%,
    "col-offset-9": 45%,
    "col-offset-10": 50%,
    "col-offset-11": 55%,
    "col-offset-12": 60%,
    "col-offset-13": 65%,
    "col-offset-14": 70%,
    "col-offset-15": 75%,
    "col-offset-16": 80%,
    "col-offset-17": 85%,
    "col-offset-18": 90%,
    "col-offset-19": 95%,
    "col-offset-20": 100%
);

@each $col,$width in $grid-columns {
    .#{$col} {
        flex: 0 0 auto;
        padding: $gutter;
        width: #{$width};
    }
}

@each $key, $val in $breakpoints {
    @media screen and (min-width: #{$val}) {
        .#{$key + $separator} {
            @each $col,$width in $grid-columns {
                &#{$col} {
                    flex: 0 0 auto;
                    padding: $gutter;
                    width: #{$width};
                }
            }
        }
    }
}

@include style-class('margin-left', $grid-column-offsets, true);

.container{
  margin: 0 1rem;
  max-width: 100%;
}
