csgofast-desktop-right-sidebar {
  mat-tab-group {
    .mat-tab-header-pagination {
      display: none;
    }
    //.mat-tab-body.mat-tab-body-active {
    //  overflow-y: hidden;
    //}
    .mat-tab-labels {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
    }

    .mat-tab-label {
      flex: 1;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      height: var(--mat-tab-label-height);
      color: var(--color-typography-muted-70);
      padding: 0;
      min-width: auto;
      text-align: center;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      &:hover {
        cursor: pointer;
      }
      mat-icon {
        width: 1rem;
        height: 1rem;
      }
    }
    .mat-tab-label-active {
      color: var(--color-typography-light);
    }

    .market__empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3rem 1rem;
      color: var(--color-typography-muted-70);
      .market__icon {
        width: 3.25rem;
        height: 3.25rem;
      }
      .market__description {
        font-size: 0.75rem;
        margin: 1.5rem 0;
        line-height: 1.58;
        letter-spacing: 0.89px;
        text-align: center;
      }
      .market__button {
        width: 100%;
        height: 2.625rem;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 1.03px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
