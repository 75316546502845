.custom-slider {
  width: 100%;
  .mat-slider-wrapper {
    .mat-slider-track-wrapper {
      .mat-slider-track-fill {
        background-color: var(--color-primary);
      }
    }
    .mat-slider-thumb-container {
      .mat-slider-thumb,
      .mat-slider-thumb-label {
        background-color: var(--color-primary);
        .mat-slider-thumb-label-text {
          color: var(--color-typography-dark);
        }
      }
    }
  }
}