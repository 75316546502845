button {
  cursor: pointer;
  font-family: FastFont, "Roboto", Helvetica, "PT Sans", Arial, Tahoma, Verdana, sans-serif;

  &.cdk-keyboard-focused {
    outline: 2px solid var(--color-outline);
  }
  &:disabled {
    color: var(--color-typography-gray-200) !important;
    pointer-events: none;
  }
}

.btn-group {
  position: relative;
  margin: 0.5rem;
  & > .btn,
  & > .dropdown,
  & > .btn.btn-sm,
  & > .btn.btn-lg {
    margin: 0;
    border-radius: 0;

    position: static;
  }

  & > .btn {
    border-width: 2px;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  & > .dropdown {
    .btn {
      border-radius: 0;
    }

    &:first-child {
      .btn {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .btn {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .dropdown-list {
    //border-width: 0;
    top: calc(100% - 2px);
  }
}

@mixin btn-mixin($text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color}-active);
  }

  &:active {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color}-active);
  }
}

@mixin btn-mixin-darken($text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-outline);
    border-color: var(#{$background-color}-outline);
  }

  &:active {
    color: $text-color;
    background-color: $background-color;
    border-color: $background-color;
  }
}

@mixin btn-outline-mixin($text-color, $alter-text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color}-outline);
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$alter-text-color});
    background-color: var(#{$background-color});
    border-color: var(#{$background-color});
  }

  &:active {
    color: var(#{$alter-text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color});
  }
}

@mixin btn-transparent-mixin($text-color, $border-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: var(#{$border-color});

  &:hover {
    color: var(#{$text-color}-active);
    border-color: var(#{$border-color}-active);
  }

  &:active {
    color: var(#{$text-color}-active);
    border-color: var(#{$border-color}-active);
  }
}

@mixin btn-inversion-mixin($text-color, $hover-text-color, $border-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: var(#{$border-color});

  &:hover {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color});
    border-color: var(#{$text-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color}-active);
  }
}

@mixin btn-gray-inversion-mixin($text-color, $hover-text-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: var(#{$text-color});

  &:hover {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color});
    border-color: var(#{$text-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color});
  }
}
@mixin btn-theme-mixin($text-color, $hover-text-color, $background-color, $hover-background-color) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$hover-background-color});

  &:hover {
    color: var(#{$hover-text-color});
    background-color: var(#{$hover-background-color});
    border-color: var(#{$hover-background-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: var(#{$background-color});
  }
}

.btn {
  position: relative;
  outline: none;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 1.25rem;
  // margin: 0.5rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 0.25rem;
  text-decoration: none;
  letter-spacing: 0.6px;
  transition: 
    color 0.15s ease-in-out, 
    background-color 0.15s ease-in-out, 
    border-color 0.15s ease-in-out,
    opacity 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    color: var(--color-typography-dark);
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-empty {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  &.btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    padding: 0.25rem 1rem;
    &.sq {
      padding: 0.25rem
    }
  }

  &.btn-lg {
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    &.sq {
      padding: 0.5rem
    }
  }

  &.btn-back {
    background: none;
    color: var(--color-typography-muted-30);
    padding: 0.5rem;
    border: 1px solid var(--color-theme-400);
    border-radius: 0.25rem;
    &:hover {
      border: 1px solid var(--color-theme-400);
      color: var(--color-typography-muted-70);
    }
  }
  &.w-f {
    width: 100%
  }
  &.w-s {
    padding: 0.5rem;
  }
  &.j-sb{
    justify-content: space-between;
  }
  &.j-fs{
    justify-content: flex-start;
  }
  .icon {
    &.sm {
      width: 1rem;
      height: 1rem;
    } 
    &.md {
      width: 1.25rem;
      height: 1.25rem;
    } 
    &.lg {
      width: 1.5rem;
      height: 1.5rem;
    } 
  }

  @include btn-mixin(--color-typography-light, --color-theme-800, 20%);

  .icomoon:before {
    transition: color 0.15s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-link {
  color: var(--color-secondary);
  background-color: transparent;
  border-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
}

.btn-light {
  @include btn-mixin-darken(--color-theme-200, --color-typography-light, 20%);
}

.btn-dark {
  @include btn-mixin(--color-typography-light, --color-theme-600, 20%);
}

.btn-darken {
  @include btn-mixin(--color-typography-light, --color-theme-400, 20%);
}

.btn-primary {
  @include btn-mixin(--color-typography-dark, --color-primary, 20%);
}

.btn-secondary {
  @include btn-mixin(--color-typography-light, --color-secondary, 20%);
}

.btn-success {
  @include btn-mixin(--color-typography-light, --color-attention-success, 10%);
}

.btn-alert {
  @include btn-mixin(--color-typography-light, --color-attention-error, 15%);
}

.btn-outline {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-theme-200, 20%);
}

.btn-outline-light {
  @include btn-outline-mixin(--color-theme-200, --color-theme-200, --color-typography-light, 20%);
}

.btn-outline-dark {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-theme-600, 20%);
}

.btn-outline-primary {
  @include btn-outline-mixin(--color-typography-light, --color-typography-dark, --color-primary, 40%);
}

.btn-outline-secondary {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-secondary, 20%);
}

.btn-outline-success {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-success, 20%);
}

.btn-outline-alert {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-error, 20%);
}

.btn-transparent-primary {
  @include btn-transparent-mixin(--color-primary, --color-primary);
}

.btn-transparent-secondary {
  @include btn-transparent-mixin(--color-secondary, --color-secondary);
}

.btn-inversion-primary {
  @include btn-inversion-mixin(--color-primary, --color-typography-dark, --color-primary);
}

.btn-inversion-secondary {
  @include btn-inversion-mixin(--color-secondary, --color-typography-light, --color-secondary);
}
.btn-light-gray {
  @include btn-gray-inversion-mixin(--color-typography-muted-70, --color-typography-light);
}
.btn-gray {
  @include btn-gray-inversion-mixin(--color-typography-muted-50, --color-typography-muted-70);
}
.btn-dark-gray {
  @include btn-gray-inversion-mixin(--color-typography-muted-30, --color-typography-muted-50);
}
.btn-transparent-theme-500 {
  @include btn-theme-mixin(--color-typography-muted-50, --color-typography-light, --color-typography-transparent, --color-theme-400);
}