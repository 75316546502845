csgofast-cases {
  .cases-game__navigation {
    .mat-tab-nav-bar {
      // width: 100%;
      height: 3.5rem;
      border: none;
      // overflow-y: visible;
      .mat-tab-link-container {
        // overflow-y: visible;
        .mat-tab-list {
          .mat-tab-links {
            justify-content: center;
            align-content: flex-end;
            .mat-tab-link {
              gap: 0.5rem;
              min-width: fit-content;
              font-size: 1rem;
              opacity: 1 !important;
              color: var(--color-typography-muted-70);
              border-bottom: 1px solid var(--color-typography-muted-30);
              transition: color 150ms ease-in-out;
              height: 3rem;
              overflow: visible;
              .link-title {
                position: relative;
                color: var(--color-typography-muted-50);
                transition: color 150ms ease-in-out;
              }
              .free-cases-amount {
                background: var(--color-primary);
                color: var(--color-typography-dark);
                border-radius: 0.25rem;
                padding: 0.25rem 0.5rem;
              }
              fast-ui-tag {
                position: absolute;
                left: 100%;
                bottom: 100%;
                --font-weight: 500;
                --padding: 0;
                --size: 0.75rem;
                --background-color: none;
                --text-color: var(--color-cases-success-color);
              }
              .new-cases-point {
                position: absolute;
                left: 100%;
                bottom: 100%;
                width: 0.575rem;
                height: 0.575rem;
                background-color: var(--color-cases-success-color);
                clip-path: circle(45% at 50% 50%);
              }
              &:hover {
                .link-title {
                  color: var(--color-typography-muted-30);
                }
              }
              &.mat-tab-label-active {
                color: var(--color-primary);
                .link-title {
                  opacity: 1 !important;
                }
              }
              &.mat-tab-disabled {
                color: var(--color-typography-muted-50) !important;
                opacity: 0.5 !important;
              }
              &.free {
                color: var(--color-cases-success-color);
                .free-cases-amount {
                  background: var(--color-cases-success-color);
                }
              }
            }
          }
          .mat-ink-bar {
            bottom: 0.075rem;
            height: 0.5rem;
            background: var(--color-primary);
            clip-path: polygon(
              0% 0%,
              calc(50% - 0.2rem) 0%,
              50% 50%,
              calc(50% + 0.2rem) 0%,
              100% 0%,
              calc(100% - 0.25rem) 40%,
              calc(50% + 0.25rem) 40%,
              50% 100%,
              calc(50% - 0.25rem) 40%,
              calc(0% + 0.25rem) 40%
            );
          }
        }
      }
      .mat-ripple {
        height: 3rem;
        box-shadow: none !important;
      }
    }
    &.category_free {
      .mat-tab-nav-bar {
        .mat-tab-link-container {
          .mat-tab-list {
            .mat-ink-bar {
              background: var(--color-cases-success-color);
            }
          }
        }
      }
    }
  }
}
