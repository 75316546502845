*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  // display: flex;
  flex: 0 0 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  background-color: var(--color-theme-800);
  background-size: 100%;
  font-family: FastFont, Roboto, "PT Sans", Arial, Tahoma, Verdana, sans-serif;
  // font-family:  "Fast Font", Roboto, "PT Sans", Arial, Tahoma, Verdana, sans-serif;

  font-size: 1rem;
  text-align: left;
  color: var(--color-typography-light);
  text-size-adjust: 100%;
}
.m-0 {
  margin: 0 !important;
}
.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
img {
  object-fit: contain;
}
.pointer{
  cursor: pointer;
}
