.auto-play-menu {
  overflow: visible !important;
  margin: 0.5rem 0;
  min-width: 17rem !important;
  background: var(--color-theme-500);
  .auto-play-menu-checkbox {
    display: flex;
    align-items: flex-start;
    min-height: 3rem;
    width: 100%;
    font-weight: 300;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    color: var(--color-typography-muted-70);
    .mat-checkbox-label {
      line-height: 1.25rem;
      white-space: normal;
    }
    .mat-checkbox-layout {
      align-items: flex-start;
      gap: 0.75rem;
      .mat-checkbox-inner-container {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0;
        .mat-checkbox-frame {
          border: none;
        }
        .mat-checkbox-background {
          background-color: var(--color-theme-600) !important;
        }
      }
    }
    &.mat-checkbox-checked {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-background {
            background-color: var(--color-primary) !important;
            .mat-checkbox-checkmark-path {
              stroke: var(--color-theme-400) !important;
            }
          }
        }
      }
    }
  }
  .auto-play-menu-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 3rem;
    font-weight: 400;
    font-size: 0.875rem;
    border-top: 1px solid var(--color-theme-400);
    color: var(--color-typography-muted-70);
    transition: background-color 150ms ease, color 150ms ease;
    .button-text {
      display: flex;
      gap: 0.25rem;
      .text-path-1 {
        overflow: hidden;
        max-width: 0rem;
        transition: max-width 300ms cubic-bezier(0.37, 0.62, 0.37, 0.96);
      }
      .text-amount-path {
        font-weight: 700;
      }
    }
    .button-currency {
      font-family: FastFont, "Roboto";
      color: var(--color-primary);
      transition: color 150ms ease;
      .infinity-icon {
        width: 1rem;
        margin: 0;
        transition: color 150ms ease;
        color: var(--color-primary);
      }
    }
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-typography-dark);
      .button-text {
        .text-path-1 {
          max-width: 4.5rem;
        }
      }
      .button-currency {
        color: var(--color-typography-dark);
        .infinity-icon {
          color: var(--color-typography-dark);
        }
      }
    }
    &:disabled {
      background: var(--color-theme-700);
      opacity: 0.5;
    }
  }
}