// @import '~projects/assets/styles/variables';

$color-overlay: rgba(0, 0, 0, 0.8);

.no-cross {
  .nsm-dialog-btn-close {
    display: none;
  }
}

.nsm-dialog {
  max-width: 100%;
}

.md {
  .nsm-content {
    max-width: 490px !important;
  }
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 660px;
  pointer-events: auto;
  background-color: var(--color-theme-600);
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 6rem auto;
}

.modal-header {
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-modal;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.mat-dialog-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.62);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.user-profile-modal {
  .mat-dialog-container {
    border-radius: 8px;
  }
}
.gleam-modal {
  .mat-dialog-container {
    border-radius: 8px;
    padding: 0;
  }
}
.ban-modal {
  .mat-dialog-container {
    border-radius: 8px;
    background-color: var(--color-theme-400);
  }
}
.referral-modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.62);
  @keyframes spinShine {
    100% {
      transform: rotate(360deg);
    }
  }
  &:after {
    content: "";
    animation: spinShine 16s linear infinite;
    background: var(--shine-background);
    height: 65rem;
    width: 65rem;
  }
}
.user-profile-modal{
  .mat-dialog-container{
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
}
.confirm-dialog,
.tech-support-modal,
.lottery-rules-modal,
.user-profile-modal,
.revesion-modal,
.case-battle-account-type,
.case-battle-create-faq,
.case-battle-provably-modal,
.case-battle-winners,
.case-info-modal,
.ddos-modal,
.ban-modal,
.offerwall-modal,
.client-seed-history-modal,
.server-seed-history-modal,
.auth-modal {
  .mat-dialog-container {
    padding: 0;
  }
}
.case-info-modal {
  width: 100%;
  height: 100%;
  max-width: 50rem !important;
  max-height: 40rem !important;
  padding: 0.25rem;
}
.revesion-modal {
  width: 100%;
  max-width: 50rem !important;
  // max-height: 40rem !important;
  // .mat-dialog-container {
  //   overflow: initial;
  // }
}
.confirm-dialog {
  position: relative !important;
  .mat-dialog-container {
    border-radius: 6px;
    overflow: unset;
  }
}
.cases-autoplay-game {
  .mat-dialog-container {
    width: calc(100vw - 8rem);
    overflow: inherit;
    max-width: 50rem;
    padding: 0;
  }
}
.case-battle-provably-modal {
  width: 100%;
  height: 100%;
  max-width: 50rem !important;
  max-height: 40rem !important;
  padding: 0.25rem;
}
.case-battle-game-break {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
}
.steam-error-modal{
  .mat-dialog-container {
  overflow: visible;
  padding: 0;
  border-radius: 0.5rem  0;
}
}
