@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic&amp;subset=latin-ext");

@font-face {
  font-family: "FastFont";
  src: url("../fonts/FastFont/icomoon.eot");
  src: url("../fonts/FastFont/icomoon.eot#iefix") format("embedded-opentype"),
    url("../fonts/FastFont/icomoon.ttf") format("truetype"),
    url("../fonts/FastFont/icomoon.woff") format("woff"),
    url("../fonts/FastFont/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  // unicode-range: U+25Ce, U+2b06, U+1f0cf, U+1f465, U+1f512, U+1f513, U+1f5fc;
  unicode-range: U+25Ce;
}
/* jolly-lodger-regular - latin */
@font-face {
  font-family: "JollyLodger";
  src: url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/JollyLodger/jolly-lodger-v20-latin-regular.svg") format("svg"); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

// @media screen and (max-width: $screen-lg) {
html {
  @include fluidFontSize(12px, 16px, 480px, 1920px, 16px);
}
// }

// @media screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
//   html {
//     font-size: 0.833333vw;
//   }
// }

// @media screen and (min-width: ( $screen-xl + 1)) {
//   html {
//     font-size: calc(0.53vw + 5px);
//   }
// }

// @media screen and (min-width: $screen-xxl) {
//   html {
//     font-size: 100%;
//   }
// }
