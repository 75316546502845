.flex {
  display: flex;

  &.fx-column {
    flex-direction: column;
  }

  &.fx-justify-center {
    justify-content: center;
  }

  &.fx-align-center {
    align-items: center;
  }
}
