csgofast-cases-content {
  .mat-tab-group {
    font-family: inherit;
    .mat-tab-header {
      overflow: visible;
      .mat-tab-label-container {
        overflow: visible;
        .mat-tab-list {
          padding-left: 35px;
          @media screen and (max-width:350px) {
            padding-left: 0;
          }
          .mat-ink-bar {
            top: 100%;
            height: 1rem;
            background: var(--color-primary);
            clip-path: polygon(
              0% 0%,
              calc(50% - 0.2rem) 0%,
              50% 18%,
              calc(50% + 0.2rem) 0%,
              100% 0%,
              calc(100% - 0.25rem) 15%,
              calc(50% + 0.25rem) 15%,
              50% 38%,
              calc(50% - 0.25rem) 15%,
              calc(0% + 0.25rem) 15%
            );
          }
        }
      }
    }
  }
  .mat-tab-label-active{
    color: #f6e444;
    opacity: 1 !important;
  }
}
