.menu-item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  text-decoration: none;
  transition: background-color .15s ease-in,color .15s ease-in;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
}
