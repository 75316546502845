.mat-menu--cases-filter  { 
  &.mat-menu{
    margin: 1.25rem 0;
    .mat-menu-content {
      min-height: 14rem;
    }
    .mat-menu-content:not(:empty) {
      max-height: inherit !important;
      width: 15.2rem;
    }
  }
}
 // .cases {
  //   &__sort-list,
  //   &__price-list {
  //     box-shadow: none;
  //     background: var(--color-theme-500);
  //   }
  // }

  // .custom-form-field {
  //   letter-spacing: 0;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 0.875rem;
  //   vertical-align: baseline;
  //   .mat-form-field-wrapper {
  //     height: 1.5rem;
  //     .mat-form-field-flex {
  //       .mat-form-field-infix {
  //         display: flex;
  //         align-items: flex-end;
  //         height: 1.5rem;
  //       }
  //     }
  //   }
  // }
  // .mat-form-field-wrapper,
  // .mat-form-field-appearance-outline,
  // .mat-form-field-infix {
  //   padding: 0 !important;
  //   margin: 0 !important;
  //   outline: none !important;
  //   border: none !important;
  //   width: auto !important;
  //   .mat-select {
  //     width: auto;
  //     .mat-select-trigger {
  //       width: auto;
  //       .mat-select-value {
  //         font-family: FastFont, 'Roboto';
  //         transition: color 300ms ease;
  //         color: #a7adaf;
  //         width: auto;
  //       }
  //       .mat-select-arrow-wrapper {
  //         display: none;
  //       }
  //     }
  //     &:hover {
  //       .mat-select-trigger {
  //         .mat-select-value {
  //           color: #ffffff;
  //         }
  //       }
  //     }
  //   }
  // }
  // .mat-form-field-underline {
  //   display: none;
  // }

  // .mat-select-panel {
  //   background: transparent !important;
  //   min-width: 0 !important;
  //   width: auto;
  //   .cases__sort-btn {
  //     display: flex;
  //     justify-content: flex-start;
  //     align-items: center;
  //     height: 3rem;
  //     letter-spacing: 0;
  //     font-family: FastFont, 'Roboto';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 0.875rem;
  //     color: #ffffff;
  //     transition: 150ms ease;
  //     background: #213137;
  //     .mat-option-text {
  //       color: #ffffff;
  //     }
  //     &:hover {
  //       background: #182328 !important;
  //       .mat-option-text {
  //         color: #f6e444;
  //       }
  //     }
  //   }
  //   .mat-option.mat-selected:not(.mat-option-multiple) {
  //     background: #182328 !important;
  //     .mat-option-text {
  //       color: #a7adaf;
  //     }
  //   }
  // }